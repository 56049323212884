import { serverNamePhp, request } from '../configs.js';

export const downloadRequests = {
  downloadPackage: (type, name) => {
    return request.get(`${serverNamePhp}/download.php?tipo=${type}&nome=${name}`, {
      responseType: 'blob', // Indica que a resposta é um blob (arquivo)
    })
  }
};

export default downloadRequests;

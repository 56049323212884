import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import './AgendaSemanaForm.scss';

const { Option } = Select;

const AgendaSemanaForm = () => {

  function agenda(parentName) {
    return (
      <>
        <h4>Eventos</h4>
        <Form.List name={[parentName, 'itens']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div className="line"
                  key={key}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'hora']}
                    rules={[{ required: true, message: 'Campo obrigatório!' }]}
                    style={{ minWidth: 120}}
                  >
                    <Input placeholder="Hora" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'moeda']}
                    rules={[{ required: true, message: 'Campo obrigatório!' }]}
                    style={{ minWidth: 175 }}
                  >
                    <Select rules={[{ required: true, message: 'Campo obrigatório!' }]} placeholder="Local">
                      <Option value="alemanha">Alemanha (EUR)</Option>
                      <Option value="argentina">Argentina (ARS)</Option>
                      <Option value="brasil">Brasil (BRL)</Option>
                      <Option value="canada">Canadá (CAD)</Option>
                      <Option value="china">China (CNY)</Option>
                      <Option value="eua">EUA (USD)</Option>
                      <Option value="fraca">França (EUR)</Option>
                      <Option value="hongkong">Hong Kong (HKD)</Option>
                      <Option value="japao">Japão (JPY)</Option>
                      <Option value="reinounido">Reino Unido (GBP)</Option>
                      <Option value="ue">União Europeia (EUR)</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, 'descricao']}
                    rules={[{ required: true, message: 'Campo obrigatório!' }]}
                    style={{ minWidth: 120, flex: 1 }}
                  >
                    <Input placeholder="Evento" />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, 'periodo']}
                    style={{ minWidth: 60 }}
                  >
                    <Input placeholder="Período" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              ))}


              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Adicionar item
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </>
    );
  }



  return <div className="AgendaSemanaForm">

    <Form.Item
      name="agenda_semana_data_inicial"
      label="De"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="agenda_semana_data_final"
      label="Até"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    <Form.List name="agenda_semana">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <div className="schedule"
              key={key}
              align="baseline"
            >
              <h3>Dia</h3>
              <div className="line">
                <Form.Item
                  {...restField}
                  name={[name, 'dia_semana']}
                  rules={[{ required: true, message: 'Campo obrigatório!' }]}
                  style={{ minWidth: 120 }}
                >
                  <Select placeholder="Dia semana">
                    <Option value="Segunda-feira">Segunda-feira</Option>
                    <Option value="Terça-feira">Terça-feira</Option>
                    <Option value="Quarta-feira">Quarta-feira</Option>
                    <Option value="Quinta-feira">Quinta-feira</Option>
                    <Option value="Sexta-feira">Sexta-feira</Option>
                    <Option value="Sábado">Sábado</Option>
                    <Option value="Domingo">Domingo</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'data']}
                  rules={[{ required: true, message: 'Campo obrigatório!' }]}
                  style={{ minWidth: 120, flex: 1 }}
                >
                  <Input placeholder="Data" />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </div>
              <div>{agenda(name)}</div>
            </div>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Adicionar dia
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>

  </div>
};
export default AgendaSemanaForm;
import React from 'react';
import { Form, Input, Select } from 'antd';
import './MercadosGlobaisForm.scss';

const { Option } = Select;

const MercadosGlobaisForm = () => {
  return <div className="MercadosGlobaisForm">

    <Form.Item
      name="mercados_globais_data"
      label="Data"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>


    <Form.Item
      name="mercados_globais_hora"
      label="Hora"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>


    <h3>Nasdaq</h3>
    <div className="line">
      <Form.Item
        name="mercados_globais_nasdaq_pontos"
        label="Pontos"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="mercados_globais_nasdaq_variacao"
        label="Variação %"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercados_globais_nasdaq_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Alta</Option>
          <Option value="diminuicao">Baixa</Option>
        </Select>
      </Form.Item>
    </div>


    <h3>Dow Jones</h3>
    <div className="line">
      <Form.Item
        name="mercados_globais_dow_jones_pontos"
        label="Pontos"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="mercados_globais_dow_jones_variacao"
        label="Variação %"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercados_globais_dow_jones_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Alta</Option>
          <Option value="diminuicao">Baixa</Option>
        </Select>
      </Form.Item>
    </div>


    <h3>S&P 500</h3>
    <div className="line">
      <Form.Item
        name="mercados_globais_sp_500_pontos"
        label="Pontos"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="mercados_globais_sp_500_variacao"
        label="Variação %"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercados_globais_sp_500_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Alta</Option>
          <Option value="diminuicao">Baixa</Option>
        </Select>
      </Form.Item>
    </div>


    <h3>Ibovespa</h3>
    <div className="line">
      <Form.Item
        name="mercados_globais_ibovespa_pontos"
        label="Pontos"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="mercados_globais_ibovespa_variacao"
        label="Variação %"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercados_globais_ibovespa_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Alta</Option>
          <Option value="diminuicao">Baixa</Option>
        </Select>
      </Form.Item>
    </div>


    <h3>Merval</h3>
    <div className="line">
      <Form.Item
        name="mercados_globais_merval_pontos"
        label="Pontos"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="mercados_globais_merval_variacao"
        label="Variação %"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercados_globais_merval_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Alta</Option>
          <Option value="diminuicao">Baixa</Option>
        </Select>
      </Form.Item>
    </div>


    <h3>Paris</h3>
    <div className="line">
      <Form.Item
        name="mercados_globais_paris_pontos"
        label="Pontos"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="mercados_globais_paris_variacao"
        label="Variação %"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercados_globais_paris_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Alta</Option>
          <Option value="diminuicao">Baixa</Option>
        </Select>
      </Form.Item>
    </div>


    <h3>Londres</h3>
    <div className="line">
      <Form.Item
        name="mercados_globais_londres_pontos"
        label="Pontos"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="mercados_globais_londres_variacao"
        label="Variação %"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercados_globais_londres_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Alta</Option>
          <Option value="diminuicao">Baixa</Option>
        </Select>
      </Form.Item>
    </div>


    <h3>Frankfurt</h3>
    <div className="line">
      <Form.Item
        name="mercados_globais_frankfurt_pontos"
        label="Pontos"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="mercados_globais_frankfurt_variacao"
        label="Variação %"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercados_globais_frankfurt_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Alta</Option>
          <Option value="diminuicao">Baixa</Option>
        </Select>
      </Form.Item>
    </div>


    <h3>Hang Seng</h3>
    <div className="line">
      <Form.Item
        name="mercados_globais_hang_seng_pontos"
        label="Pontos"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="mercados_globais_hang_seng_variacao"
        label="Variação %"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercados_globais_hang_seng_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Alta</Option>
          <Option value="diminuicao">Baixa</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="mercados_globais_hang_seng_fechamento"
        label="Fechamento"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>


    <h3>Nikkei</h3>
    <div className="line">
      <Form.Item
        name="mercados_globais_nikkei_pontos"
        label="Pontos"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="mercados_globais_nikkei_variacao"
        label="Variação %"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercados_globais_nikkei_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Alta</Option>
          <Option value="diminuicao">Baixa</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="mercados_globais_nikkei_fechamento"
        label="Fechamento"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>



  </div>
};
export default MercadosGlobaisForm;
import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import './FundosImobiliariosForm.scss';

const { Option } = Select;

const FundosImobiliariosForm = () => {

  return <div className="FundosImobiliariosForm">

    <div className="line">
      <Form.Item
        name="fundos_imobiliarios_data"
        label="Data"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="fundos_imobiliarios_horario_brasilia"
        label="Horário de Brasília"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>


    <div className="line">
      <Form.Item
        name="fundos_imobiliarios_ifix_valor"
        label="Valor"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="fundos_imobiliarios_ifix_variacao"
        label="Variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="fundos_imobiliarios_ifix_status" label="Variação" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="estabilidade">Estabilidade</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>


    <h3>Maiores altas</h3>
    <Form.List name="fundos_imobiliarios_altas">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <div className="line"
              key={key}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'sigla']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <Input placeholder="Sigla" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'variacao']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <Input placeholder="Variação" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'valor']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <Input placeholder="Valor" />
              </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)} />
            </div>
          ))}


          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Adicionar item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>


    <h3>Maiores baixas</h3>
    <Form.List name="fundos_imobiliarios_baixas">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <div className="line"
              key={key}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'sigla']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <Input placeholder="Sigla" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'variacao']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <Input placeholder="Variação" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'valor']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
              >
                <Input placeholder="Valor" />
              </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)} />
            </div>
          ))}


          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Adicionar item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>

  </div>
};
export default FundosImobiliariosForm;
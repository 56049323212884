import React from 'react';
import { Button, ColorPicker, Form, InputNumber, Tooltip, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { dummyRequest, normFile } from '../../../utils/upload';
import { InfoCircleOutlined } from '@ant-design/icons';


import './ImagemPersonalizadaForm.scss';

const { Option } = Select;

const ImagemPersonalizadaForm = () => {

  return <div className="ImagemPersonalizadaForm">

    <h3>Nome do assessor</h3>
    <div className="line">
      <Form.Item
        name="imagem_personalizada_profile_x"
        label="X (horizontal)"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="imagem_personalizada_profile_y"
        label="Y (vertical)"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="imagem_personalizada_profile_cor"
        label="Cor texto"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <ColorPicker disabledAlpha defaultFormat="rgb" />
      </Form.Item>

      <Form.Item
        name="imagem_personalizada_profile_fundo"
        label={
          <Tooltip title="Quando a cor de fundo for predominantemente escura, será usado um logo claro e vice-versa.">
            Fundo <InfoCircleOutlined />
          </Tooltip>
        }
        className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Select>
          <Option value="escuro">Claro</Option>
          <Option value="claro">Escuro</Option>
        </Select>
      </Form.Item>

    </div>

    <h3>QR Code</h3>
    <div className="line">
      <Form.Item
        name="imagem_personalizada_qr_x"
        label="X (horizontal)"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="imagem_personalizada_qr_y"
        label="Y (vertical)"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="imagem_personalizada_qr_borda"
        label="Largura borda"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="imagem_personalizada_qr_tamanho"
        label="Tamanho"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <InputNumber />
      </Form.Item>
    </div>

    <Form.Item
      name="imagem_personalizada_imagem"
      label="Imagem"
      valuePropName="fileList"
      getValueFromEvent={normFile}
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Upload
        name="imagem_personalizada_imagem"
        customRequest={dummyRequest}
        accept=".jpg,.jpeg,.png,.gif"
        listType="picture"
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
      </Upload>
    </Form.Item>

  </div>
};
export default ImagemPersonalizadaForm;
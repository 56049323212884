import React from 'react';
import { Button, Checkbox, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import './TesouroDiretoFeedForm.scss';

const { Option } = Select;

const TesouroDiretoFeedForm = () => {

  return <div className="TesouroDiretoFeedForm">

    <Form.Item
      name="tesouro_direto_feed_data"
      label="Data agenda econômica"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      name="tesouro_direto_feed_horario_brasilia"
      label="Horário"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>


    <Form.Item
      name="tesouro_direto_feed_color"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
      style={{ minWidth: 120}}
    >
      <Select rules={[{ required: true, message: 'Campo obrigatório!' }]} placeholder="Cor">
        <Option value="blue">Azul</Option>
        <Option value="red">Vermelho</Option>
      </Select>
    </Form.Item>


    <h3>Itens:</h3>
    <Form.List name="tesouro_direto_feed">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <div className="line"
              key={key}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'value']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input placeholder="Valor" />
              </Form.Item>


              <Form.Item
                {...restField}
                name={[name, 'name']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input placeholder="Nome" />
              </Form.Item>


              <Form.Item
                {...restField}
                name={[name, 'year']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input placeholder="Ano" />
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'date']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input placeholder="Data" />
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'juros_semestrais']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
                valuePropName="checked"
              >
                <Checkbox>Juros semestrais?</Checkbox>
              </Form.Item>

              <MinusCircleOutlined onClick={() => remove(name)} />
            </div>
          ))}


          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Adicionar item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>

  </div>
};
export default TesouroDiretoFeedForm;
import React from 'react';
import { Form, Input } from 'antd';
import './FeedDropForm.scss';

const FeedDropForm = () => {

  return <>
    <div className="line-full">
      <Form.Item
        name="date"
        label="Data"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="line-half-container">
      <div className="line-half">
        <Form.Item
          name="feed_baixa_nome1"
          label="Nome baixa 1"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="feed_baixa_variacao1"
          label="Variação baixa 1"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
      </div>
      <div className="line-half">
        <Form.Item
          name="feed_baixa_nome2"
          label="Nome baixa 2"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="feed_baixa_variacao2"
          label="Variação baixa 2"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
      </div>
    </div>
    <div className="line-half-container">
      <div className="line-half">
        <Form.Item
          name="feed_baixa_nome3"
          label="Nome baixa 3"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="feed_baixa_variacao3"
          label="Variação baixa 3"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
      </div>
      <div className="line-half">
        <Form.Item
          name="feed_baixa_nome4"
          label="Nome baixa 4"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="feed_baixa_variacao4"
          label="Variação baixa 4"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
      </div>
    </div>
    <div className="line-half-container">
      <div className="line-half">
        <Form.Item
          name="feed_baixa_nome5"
          label="Nome baixa 5"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="feed_baixa_variacao5"
          label="Variação baixa 5"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
      </div>
      <div className="line-half">
        <Form.Item
          name="feed_baixa_nome6"
          label="Nome baixa 6"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="feed_baixa_variacao6"
          label="Variação baixa 6"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
      </div>
    </div>
  </>
};
export default FeedDropForm;
import { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const getUserFromStorage = () => {
    // Tente obter o usuário a partir do localStorage
    const storedUser = localStorage.getItem('user');
    try {
      return storedUser ? JSON.parse(storedUser) : null;
    } catch {
      // Remover dados do usuário do localStorage
      localStorage.removeItem('user');
      return null;
    }
  };

  const [user, setUser] = useState(getUserFromStorage());


  const login = (token, permissions, userData) => {
    const newUser = { token, permissions, userData };

    // Lógica de autenticação, por exemplo, chamar uma API de login
    setUser(newUser);

    // Armazenar dados do usuário no localStorage
    localStorage.setItem('user', JSON.stringify(newUser));
  };

  const logout = () => {
    // Lógica de logout
    setUser(null);

    // Remover dados do usuário do localStorage
    localStorage.removeItem('user');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
import React, { useEffect } from 'react';
import './Home.scss';
import { useNavigate } from 'react-router-dom';
import { pagesList } from '../../constants/constants';

// Somente redireciona para uma página válida
function Home() {
  const navigate = useNavigate();
    useEffect(() => {
      navigate(`/${Object.values(pagesList)[0].slug}`);
    }, [navigate]);

  return <></>
}

export default Home;

import React from 'react';
import { Button, Form, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import './JornalForm.scss';
import { dummyRequest, normFile } from '../../../utils/upload';

const JornalForm = () => {
  return <div className="JornalForm">
    <Form.Item
      name="jornal_imagem1"
      label="Página 1"
      valuePropName="fileList"
      getValueFromEvent={normFile}
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Upload
        name="jornal_imagem1"
        customRequest={dummyRequest}
        accept=".jpg,.jpeg,.png,.gif"
        listType="picture"
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
      </Upload>
    </Form.Item>


    <Form.Item
      name="jornal_imagem2"
      label="Página 2"
      valuePropName="fileList"
      getValueFromEvent={normFile}
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Upload
        name="jornal_imagem2"
        customRequest={dummyRequest}
        accept=".jpg,.jpeg,.png,.gif"
        listType="picture"
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
      </Upload>
    </Form.Item>


    <Form.Item
      name="jornal_imagem3"
      label="Página 3"
      valuePropName="fileList"
      getValueFromEvent={normFile}
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Upload
        name="jornal_imagem3"
        customRequest={dummyRequest}
        accept=".jpg,.jpeg,.png,.gif"
        listType="picture"
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
      </Upload>
    </Form.Item>


    <Form.Item
      name="jornal_imagem4"
      label="Página 4"
      valuePropName="fileList"
      getValueFromEvent={normFile}
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Upload
        name="jornal_imagem4"
        customRequest={dummyRequest}
        accept=".jpg,.jpeg,.png,.gif"
        listType="picture"
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
      </Upload>
    </Form.Item>


    <Form.Item
      name="jornal_imagem5"
      label="Página 5"
      valuePropName="fileList"
      getValueFromEvent={normFile}
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Upload
        name="jornal_imagem5"
        customRequest={dummyRequest}
        accept=".jpg,.jpeg,.png,.gif"
        listType="picture"
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
      </Upload>
    </Form.Item>


    <Form.Item
      name="jornal_imagem6"
      label="Página 6"
      valuePropName="fileList"
      getValueFromEvent={normFile}
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Upload
        name="jornal_imagem6"
        customRequest={dummyRequest}
        accept=".jpg,.jpeg,.png,.gif"
        listType="picture"
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
      </Upload>
    </Form.Item>


    <Form.Item
      name="jornal_imagem7"
      label="Página 7"
      valuePropName="fileList"
      getValueFromEvent={normFile}
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Upload
        name="jornal_imagem7"
        customRequest={dummyRequest}
        accept=".jpg,.jpeg,.png,.gif"
        listType="picture"
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
      </Upload>
    </Form.Item>

  </div>
};
export default JornalForm;
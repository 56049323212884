import React from 'react';
import { Form, Input, Select } from 'antd';
import './FocusForm.scss';

const { Option } = Select;

const FocusForm = () => {
  return <div className="FocusForm">
    <div className="line-full">
      <Form.Item name="focus_modelo" label="Modelo" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="feed">Feed</Option>
          <Option value="stories">Stories</Option>
        </Select>
      </Form.Item>
    </div>

    <div className="line-full">
      <Form.Item
        name="date"
        label="Data"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="focus_year_last"
        label="Ano atual"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="focus_year_current"
        label="Ano futuro"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="line-half-container">
      <div className="line-half">
        <Form.Item
          name="focus_ipca_last"
          label="IPCA atual valor"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="focus_ipca_last_status" label="IPCA atual status" className="select" rules={[{ required: true }]}>
          <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
            <Option value="aumento">Aumento</Option>
            <Option value="estabilidade">Estabilidade</Option>
            <Option value="diminuicao">Diminuição</Option>
          </Select>
        </Form.Item>
      </div>

      <div className="line-half">
        <Form.Item
          name="focus_ipca_current"
          label="IPCA futuro"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="focus_ipca_current_status" label="IPCA futuro status" className="select" rules={[{ required: true }]}>
          <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
            <Option value="aumento">Aumento</Option>
            <Option value="estabilidade">Estabilidade</Option>
            <Option value="diminuicao">Diminuição</Option>
          </Select>
        </Form.Item>
      </div>
    </div>

    <div className="line-half-container">
      <div className="line-half">
        <Form.Item
          name="focus_cambio_last"
          label="Câmbio atual valor"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="focus_cambio_last_status" label="Câmbio atual status" className="select" rules={[{ required: true }]}>
          <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
            <Option value="aumento">Aumento</Option>
            <Option value="estabilidade">Estabilidade</Option>
            <Option value="diminuicao">Diminuição</Option>
          </Select>
        </Form.Item>
      </div>

      <div className="line-half">
        <Form.Item
          name="focus_cambio_current"
          label="Câmbio futuro valor"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="focus_cambio_current_status" label="Câmbio futuro status" className="select" rules={[{ required: true }]}>
          <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
            <Option value="aumento">Aumento</Option>
            <Option value="estabilidade">Estabilidade</Option>
            <Option value="diminuicao">Diminuição</Option>
          </Select>
        </Form.Item>
      </div>
    </div>

    <div className="line-half-container">
      <div className="line-half">
        <Form.Item
          name="focus_pib_last"
          label="PIB atual valor"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="focus_pib_last_status" label="PIB atual status" className="select" rules={[{ required: true }]}>
          <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
            <Option value="aumento">Aumento</Option>
            <Option value="estabilidade">Estabilidade</Option>
            <Option value="diminuicao">Diminuição</Option>
          </Select>
        </Form.Item>
      </div>

      <div className="line-half">
        <Form.Item
          name="focus_pib_current"
          label="PIB futuro valor"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="focus_pib_current_status" label="PIB futuro status" className="select" rules={[{ required: true }]}>
          <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
            <Option value="aumento">Aumento</Option>
            <Option value="estabilidade">Estabilidade</Option>
            <Option value="diminuicao">Diminuição</Option>
          </Select>
        </Form.Item>
      </div>
    </div>

    <div className="line-half-container">
      <div className="line-half">
        <Form.Item
          name="focus_selic_last"
          label="SELIC atual valor"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="focus_selic_last_status" label="SELIC atual status" className="select" rules={[{ required: true }]}>
          <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
            <Option value="aumento">Aumento</Option>
            <Option value="estabilidade">Estabilidade</Option>
            <Option value="diminuicao">Diminuição</Option>
          </Select>
        </Form.Item>
      </div>

      <div className="line-half">
        <Form.Item
          name="focus_selic_current"
          label="SELIC futuro valor"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="focus_selic_current_status" label="SELIC futuro status" className="select" rules={[{ required: true }]}>
          <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
            <Option value="aumento">Aumento</Option>
            <Option value="estabilidade">Estabilidade</Option>
            <Option value="diminuicao">Diminuição</Option>
          </Select>
        </Form.Item>
      </div>
    </div>
  </div>
};
export default FocusForm;
import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import './AgendaDiaForm.scss';

const { Option } = Select;

const AgendaDiaForm = () => {

  return <div className="AgendaDiaForm">

    <Form.Item
      name="agenda_dia_data"
      label="Data agenda econômica"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      name="agenda_dia_data_extenso"
      label="Data"
    >
      <Input />
    </Form.Item>


    <h3>Eventos</h3>
    <Form.List name="agenda_dia">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <div className="line"
              key={key}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'hora']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120}}
              >
                <Input placeholder="Hora" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'moeda']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 175 }}
              >
                <Select rules={[{ required: true, message: 'Campo obrigatório!' }]} placeholder="Local">
                  <Option value="alemanha">Alemanha (EUR)</Option>
                  <Option value="argentina">Argentina (ARS)</Option>
                  <Option value="brasil">Brasil (BRL)</Option>
                  <Option value="canada">Canadá (CAD)</Option>
                  <Option value="china">China (CNY)</Option>
                  <Option value="eua">EUA (USD)</Option>
                  <Option value="fraca">França (EUR)</Option>
                  <Option value="hongkong">Hong Kong (HKD)</Option>
                  <Option value="japao">Japão (JPY)</Option>
                  <Option value="reinounido">Reino Unido (GBP)</Option>
                  <Option value="ue">União Europeia (EUR)</Option>
                </Select>
              </Form.Item>

              <Form.Item
                {...restField}
                name={[name, 'descricao']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                style={{ minWidth: 120, flex: 1 }}
              >
                <Input placeholder="Evento" />
              </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)} />
            </div>
          ))}


          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Adicionar item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>

  </div>
};
export default AgendaDiaForm;
import { serverNamePhp, request } from '../configs.js';

export const PackageGeneratorRequests = {
  generatePackage: (type, formData) => {
    return request.post(`${serverNamePhp}/gerar-imagens.php?tipo=${type}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
};

export default PackageGeneratorRequests;

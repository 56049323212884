import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, message } from 'antd';
import './Login.scss';
import AccessWrapper from '../accessWrapper/AccessWrapper';
import { useAuth } from '../../../router/AuthContext';
import { authenticationRequests } from '../../../requests';

const Login = () => {
  const navigate = useNavigate();
  const [formLogin] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const { user, login } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [navigate, user]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    const username = formLogin.getFieldValue('username');
    const password = formLogin.getFieldValue('password');

    try {
      setLoading(false);
      const response = await authenticationRequests.login(username, password);
      login(response.token, response.permissions, response.userData);
      navigate('/');
    } catch (error) {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: error.response?.data?.message || error.message,
      });
    }
  }, [formLogin, login, messageApi, navigate]);

  return (
    <AccessWrapper>
      <div className="Login">
        {contextHolder}
        <div className="login-form-container">
          <Form
            form={formLogin}
            className="Login-form"
            onFinish={() => handleSubmit()}
            noValidate
          >
            <Form.Item
              name="username"
            >
              <Input className="InputField" placeholder="Login" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Por favor, insira sua senha' },
                { min: 6, message: 'A senha deve ter no mínimo 6 digitos' }
              ]}
            >
              <Input className="InputField" type="password" placeholder="Senha" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="PrimaryButton" loading={isLoading}>
                ENTRAR
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </AccessWrapper>
  );
};

export default Login;

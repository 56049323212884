const meses = [
  'Janeiro', 'Fevereiro', 'Março', 'Abril',
  'Maio', 'Junho', 'Julho', 'Agosto',
  'Setembro', 'Outubro', 'Novembro', 'Dezembro'
];

const mesesSigla = [
  'Jan', 'Fev', 'Mar', 'Abr',
  'Mai', 'Jun', 'Jul', 'Ago',
  'Set', 'Out', 'Nov', 'Dez'
];

export const getMonthSigla = (offset) => {
  const dataAtual = new Date();
  let index = dataAtual.getMonth() + offset;
  while (index < 0) {
    index += 12;
  }
  index = index % 12;
  return mesesSigla[index].toUpperCase();
}

export const getTextCurrentMonth = () => {
  const dataAtual = new Date();
  const mesAtual = meses[dataAtual.getMonth()];
  const anoAtual = dataAtual.getFullYear();
  return `${mesAtual} de ${anoAtual}`;
}

export const getTextCurrentDate = () => {
  const dataAtual = new Date();

  const dia = String(dataAtual.getDate()).padStart(2, '0'); // Garante que tenha 2 dígitos (por exemplo, 05)
  const mes = meses[dataAtual.getMonth()];
  const ano = dataAtual.getFullYear();

  return `${dia} de ${mes} de ${ano}`;
}

export const getToday2Year = () => {
  const dataAtual = new Date();
  const dia = String(dataAtual.getDate()).padStart(2, '0');
  const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // O mês começa em 0 (janeiro) e termina em 11 (dezembro)
  const ano = dataAtual.getFullYear().toString().substr(-2);

  return `${dia}/${mes}/${ano}`;
}


export const getToday = () => {
  const dataAtual = new Date();
  const dia = String(dataAtual.getDate()).padStart(2, '0');
  const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // O mês começa em 0 (janeiro) e termina em 11 (dezembro)
  const ano = dataAtual.getFullYear();

  return `${dia}/${mes}/${ano}`;
}

export const getTodayDayMonth = () => {
  const dataAtual = new Date();
  const dia = String(dataAtual.getDate()).padStart(2, '0');
  const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // O mês começa em 0 (janeiro) e termina em 11 (dezembro)

  return `${dia}/${mes}`;
}

export const getCurrentYear = () => {
  const dataAtual = new Date();
  return dataAtual.getFullYear();
}

export const dataExtenso = (dataStr) => {
  // Converte a string de data 'dd/mm/yyyy' para o formato 'yyyy-mm-ddT00:00:00Z'
  // O 'Z' ao final indica que a data está em UTC
  const [dia, mes, ano] = dataStr.split('/');
  const data = new Date(`${ano}-${mes}-${dia}T00:00:00Z`);

  // Opções para o formato desejado
  const opcoes = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    timeZone: 'UTC'  // Assegura que a formatação será feita considerando o fuso horário UTC
  };

  // Formatar a data
  let dataFormatada = new Intl.DateTimeFormat('pt-BR', opcoes).format(data);

  // Capitaliza a primeira letra
  dataFormatada = dataFormatada.charAt(0).toUpperCase() + dataFormatada.slice(1);

  return dataFormatada;
}

export const getPreviousDay = () => {
    // Cria um objeto de data representando a data e hora atual
    const today = new Date();

    // Subtrai um dia do objeto de data atual
    const yesterday = new Date(today.setDate(today.getDate() - 1));

    // Formata a data como 'dia/mês'
    const day = yesterday.getDate().toString().padStart(2, '0');
    const month = (yesterday.getMonth() + 1).toString().padStart(2, '0'); // getMonth retorna mês de 0-11

    return `${day}/${month}`;
}
import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './AberturaMercadoMontebravoForm.scss';

const { Option } = Select;

const AberturaMercadoMontebravoForm = () => {

  return <div className='AberturaMercadoMontebravoForm'>
    <div className="line-full">
      <Form.Item
        name="date"
        label="Data"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h3>Altas</h3>
    <Form.List name="abertura_mercado_montebravo">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <div className="line" key={key} align="baseline">

              <Form.Item
                {...restField}
                name={[name, 'nome']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                label="Nome"
              >
                <Input placeholder="Nome" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'tipo']}
                label="Tipo"
              >
                <Input placeholder="Tipo" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'variacao']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                label="Variação"
              >
                <Input placeholder="Variação" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'total']}
                rules={[{ required: true, message: 'Campo obrigatório!' }]}
                label="Valor"
              >
                <Input placeholder="Valor" />
              </Form.Item>

              <Form.Item name={[name, 'status']} label="Status" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                <Select placeholder="Status">
                  <Option value="aumento">Aumento</Option>
                  <Option value="diminuicao">Diminuição</Option>
                </Select>
              </Form.Item>

              <MinusCircleOutlined onClick={() => remove(name)} />
            </div>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Adicionar item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>

  </div>
};
export default AberturaMercadoMontebravoForm;
import React, { useCallback, useEffect, useState } from 'react';
import { Button, message, Space, Table } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import moment from 'moment';
import './ListFiles.scss';
import { downloadRequests, listPackagesRequests } from '../../requests';
import { serverNamePhp } from '../../requests/configs.js';

const ListFiles = ({ type, version, setLoading }) => {
  const [data, setData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const fetchData = useCallback(async () => {
    // Função para fazer a solicitação GET
    try {
      setLoading(true);
      const response = await listPackagesRequests.listPackages(type);
      if (!response.data.error) {
        setData(response.data.content.map(v => ({ ...v, date: moment(v.date)})).sort((a, b) => b.date - a.date));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setData([]); // Limpa os dados caso ocorra um erro
      messageApi.open({
        type: 'error',
        content: error.response?.data?.message || error.message,
      });
    }
  }, [messageApi, type, setLoading]);

  useEffect(() => {
    fetchData(); // Chama a função de busca quando o componente é montado
  }, [type, version, fetchData]);

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render: (text) => text,
    },
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: (text) => text.format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (values, record) => (
        <Space size="middle">
          <button className="download-button" onClick={() => downloadFile(values.name)}>Download</button>
        </Space>
      ),
    },
    {
      title: 'Link',
      key: 'link',
      render: (values, record) => `${serverNamePhp}/download.php?tipo=${type}&nome=${values.name}`,
    },
  ];

  const downloadFile = async (name) => {
    try {
      setLoading(true);
      const response = await downloadRequests.downloadPackage(type, name);

      // Cria um objeto URL para o blob e cria um link para download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name); // Substitua pelo nome desejado
      document.body.appendChild(link);
      link.click();

      // Libera o objeto URL
      window.URL.revokeObjectURL(url);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Erro ao fazer o download do arquivo:', error);
    }
  };

  return (
    <div className="ListFiles">
      {contextHolder}
      <div className="title-line"><h3>Últimos arquivos</h3> <Button onClick={() => fetchData()}><SyncOutlined /></Button></div>
      <Table columns={columns} dataSource={data} pagination={false} />
    </div>
  );
};
export default ListFiles;
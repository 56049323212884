//import { serverNameJava, request } from '../configs.js';
import { serverNamePhp, request } from '../configs.js';

export const authenticationRequests = {
  login: (username, password) => {
    const params = { username, password };
//    return request.post(`${serverNameJava}/auth/login`, params, {
    return request.post(`${serverNamePhp}/authentication.php?action=login`, params, {
      headers: {
        'Content-Type': 'application/json',
       },
     });
  }
};

export default authenticationRequests;

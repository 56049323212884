import { serverNamePhp, request } from '../configs.js';

export const imagePreviewRequests = {
  imagePreview: (type, model, formData) => {
    return request.post(`${serverNamePhp}/preview.php?tipo=${type}&modelo=${model}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    });
  },
};

export default imagePreviewRequests;

import React from 'react';
import { Button, Form, Input, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { dummyRequest, normFile } from '../../../utils/upload';

import './TwitterForm.scss';

const { TextArea } = Input;

const TwitterForm = () => {

  return <div className="TwitterForm">
    <Form.Item
      name="twitter_dia"
      label="Dia"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="twitter_hora"
      label="Hora"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      name="twitter_texto"
      label="Texto da publicação"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <TextArea rows={12} />
    </Form.Item>

    <Form.Item
      name="twitter_imagem"
      label="Imagem"
      valuePropName="fileList"
      getValueFromEvent={normFile}
    >
      <Upload
        name="twitter_imagem"
        customRequest={dummyRequest}
        accept=".jpg,.jpeg,.png,.gif"
        listType="picture"
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
      </Upload>
    </Form.Item>

  </div>
};
export default TwitterForm;
import React, { useState } from 'react';
import { Button, Form, Input, message, Select, Spin } from 'antd';

const { Option } = Select;

function GerenciarAssessores() {
  const [form] = Form.useForm();
  const [currentForm, setCurrentForm] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [version, setVersion] = useState(Math.random());
  const [loading, setLoading] = useState(false);

  function validateForm() {
  }

  return (
    <div>
      {contextHolder}
      <Spin tip="Carregando" spinning={loading}>
        <Form
          form={form}
          name="basic"
          layout="vertical"
//          initialValues={initialValues}
          autoComplete="off"
        >
          <div className="line">
            <Form.Item name="bitcoin_ethereum_modelo" label="Modelo" className="select" rules={[{ required: true }]}>
              <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
                <Option value="bitcoin">Bitcoin</Option>
                <Option value="ethereum">Ethereum</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="bitcoin_ethereum_data"
              label="Data"
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="bitcoin_ethereum_horario_brasilia"
              label="Horário de Brasília"
              rules={[{ required: true, message: 'Campo obrigatório!' }]}
            >
              <Input />
            </Form.Item>
          </div>

          <Form.Item>
            <Button type="primary" onClick={() => validateForm()}>
              Gerar pacote
            </Button>
          </Form.Item>
        </Form>

      </Spin>
    </div>
  );
}

export default GerenciarAssessores;

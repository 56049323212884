import React from 'react';
import { Form, Input, Select } from 'antd';
import './MercadoCryptoForm.scss';

const { Option } = Select;

const MercadoCryptoForm = () => {
  return <div className="MercadoCryptoForm">

    <Form.Item
      name="date"
      label="Data"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      name="mercado_crypto_hora"
      label="Hora"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>


    <h3>Bitcoin</h3>
    <div className="line">
      <Form.Item
        label="Valor U$"
        name="mercado_crypto_valor_bitcoin"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação %"
        name="mercado_crypto_variacao_bitcoin"
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercado_crypto_status_bitcoin" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>




    <h3>Ethereum</h3>
    <div className="line">
      <Form.Item
        label="Valor U$"
        name="mercado_crypto_valor_ethereum"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação %"
        name="mercado_crypto_variacao_ethereum"
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercado_crypto_status_ethereum" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>


    <h3>BNB</h3>
    <div className="line">
      <Form.Item
        label="Valor U$"
        name="mercado_crypto_valor_bnb"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação %"
        name="mercado_crypto_variacao_bnb"
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercado_crypto_status_bnb" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>


    <h3>SOLANA</h3>
    <div className="line">
      <Form.Item
        label="Valor U$"
        name="mercado_crypto_valor_solana"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação %"
        name="mercado_crypto_variacao_solana"
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercado_crypto_status_solana" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>


    <h3>XRP</h3>
    <div className="line">
      <Form.Item
        label="Valor U$"
        name="mercado_crypto_valor_xrp"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação %"
        name="mercado_crypto_variacao_xrp"
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercado_crypto_status_xrp" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>


    <h3>DOGECOIN</h3>
    <div className="line">
      <Form.Item
        label="Valor U$"
        name="mercado_crypto_valor_dogecoin"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação %"
        name="mercado_crypto_variacao_dogecoin"
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercado_crypto_status_dogecoin" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>



    <h3>CARDANO</h3>
    <div className="line">
      <Form.Item
        label="Valor U$"
        name="mercado_crypto_valor_cardano"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação %"
        name="mercado_crypto_variacao_cardano"
      >
        <Input />
      </Form.Item>
      <Form.Item name="mercado_crypto_status_cardano" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
    </div>



  </div>
};
export default MercadoCryptoForm;
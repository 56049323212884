import React from 'react';
import { Form, Input, Select } from 'antd';
import './AgriculturalCommoditiesForm.scss';

const { Option } = Select;

const AgriculturalCommoditiesForm = () => {
  return <div className="AgriculturalCommoditiesForm">

    <Form.Item
      name="date"
      label="Data (GWM e THe Hill)"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>


    <Form.Item
      name="agricultural_commodities_data"
      label="Data"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>


    <Form.Item
      name="agricultural_commodities_hora"
      label="Hora"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>


    <h3>Soja</h3>
    <div className="line">
      <Form.Item
        label="Valor Reais"
        name="agricultural_commodities_valor_real_soja"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação/mês"
        name="agricultural_commodities_variacao_soja"
      >
        <Input />
      </Form.Item>
      <Form.Item name="agricultural_commodities_status_soja" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Valor Dólar"
        name="agricultural_commodities_valor_dolar_soja"
      >
        <Input />
      </Form.Item>
    </div>



    <h3>Milho</h3>
    <div className="line">
      <Form.Item
        label="Valor Reais"
        name="agricultural_commodities_valor_real_milho"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação/mês"
        name="agricultural_commodities_variacao_milho"
      >
        <Input />
      </Form.Item>
      <Form.Item name="agricultural_commodities_status_milho" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Valor Dólar"
        name="agricultural_commodities_valor_dolar_milho"
      >
        <Input />
      </Form.Item>
    </div>



    <h3>Café</h3>
    <div className="line">
      <Form.Item
        label="Valor Reais"
        name="agricultural_commodities_valor_real_cafe"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação/mês"
        name="agricultural_commodities_variacao_cafe"
      >
        <Input />
      </Form.Item>
      <Form.Item name="agricultural_commodities_status_cafe" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Valor Dólar"
        name="agricultural_commodities_valor_dolar_cafe"
      >
        <Input />
      </Form.Item>
    </div>



    <h3>Boi Gordo</h3>
    <div className="line">
      <Form.Item
        label="Valor Reais"
        name="agricultural_commodities_valor_real_boi"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação/mês"
        name="agricultural_commodities_variacao_boi"
      >
        <Input />
      </Form.Item>
      <Form.Item name="agricultural_commodities_status_boi" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Valor Dólar"
        name="agricultural_commodities_valor_dolar_boi"
      >
        <Input />
      </Form.Item>
    </div>



    <h3>Trigo</h3>
    <div className="line">
      <Form.Item
        label="Valor Reais"
        name="agricultural_commodities_valor_real_trigo"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação/mês"
        name="agricultural_commodities_variacao_trigo"
      >
        <Input />
      </Form.Item>
      <Form.Item name="agricultural_commodities_status_trigo" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Valor Dólar"
        name="agricultural_commodities_valor_dolar_trigo"
      >
        <Input />
      </Form.Item>
    </div>




    <h3>Açúcar</h3>
    <div className="line">
      <Form.Item
        label="Valor Reais"
        name="agricultural_commodities_valor_real_acucar"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação/mês"
        name="agricultural_commodities_variacao_acucar"
      >
        <Input />
      </Form.Item>
      <Form.Item name="agricultural_commodities_status_acucar" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <div className="ant-form-item select" />
    </div>


  </div>
};
export default AgriculturalCommoditiesForm;
import React from 'react';
import { Dropdown, Menu } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './HeaderMenu.scss';
import { useAuth } from '../../../../router/AuthContext';

const HeaderMenu = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const onClick = (e) => {
    const { key } = e;
    if (key === 'logout') {
      handleLogout();
    }
  };

  const handleLogout = () => {
    logout();
    navigate(`/login`);
  };

  return (
    <div className="HeaderMenu">
      <Dropdown
        overlay={() => (
          <Menu
            className="custom-header-menu"
            onClick={onClick}
            mode="inline"
          >
            <Menu.Item
              className="logout"
              key="logout"
            >
              Sair
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <div><SettingOutlined /></div>
      </Dropdown>
    </div>
  );
};

export default HeaderMenu;

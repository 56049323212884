import React from 'react';
import './AccessWrapper.scss';
import logo from '../../../assets/images/logo.png';

const AccessWrapper = ({ children }) => {
  return (
    <div className="AccessWrapper">
      <div className="logo-container">
        <img className="logo-image" src={logo} alt="" />
      </div>
      <div className="children-container">
        {children}
      </div>
    </div>
  );
};

export default AccessWrapper;
